@use "@angular/material" as mat;
@import "styles/theme";
@import "styles/page-header-theme";
@import "styles/logo-theme";
@import "styles/sidebar-theme";
@import "styles/card-theme";
@import "styles/highlights-theme";
@import "styles/form-field-theme";
@import "styles/buttons-theme";
@import "styles/icons-theme";
@import "styles/snack-bar-theme";
@import "styles/overlay-theme";
@import "styles/dialog-theme";
@import "styles/contex-navigation-theme";
@import "styles/scrollbar-theme";
@import "styles/drag-theme";
@import "styles/tabs-theme";
@import "styles/table-theme";
@import "styles/quill-theme";

@include mat.core();

@include mat.core-theme($default-theme);
// FIXME: use dedicated material component themes instead of all-component-themes
@include mat.all-component-themes($default-theme);
@include snack-bar();

/* default theme */
body:has(.default-theme) {
  @include page-header($default-theme);
  @include logo($default-theme);
  @include sidenav($default-theme);
  @include card-theme($default-theme);
  @include highlights($default-theme);
  @include overlay($default-theme);
  @include buttons($default-theme);
  @include form-field($default-theme);
  @include dialog($default-theme);
  @include scrollbar($default-theme);
  @include drag($default-theme);
  @include context-navigation($default-theme);
  @include icons($default-theme);
  @include tabs-theme($default-theme);
  @include custom-table($default-theme);
  @include quill($default-theme);

  .contrast {
    color: black;
  }

  .primary-text {
    color: map-get($default-foreground, text);
  }

  .secondary-text {
    color: map-get($default-foreground, secondary-text);
  }

  .disabled-text {
    color: map-get($default-foreground, disabled-text);
  }

  .primary-background {
    background: map-get($default-background, background);
  }

  .secondary-background {
    background-color: map-get($default-background, secondary-background);
  }

  .secondary-background-alt {
    background-color: whitesmoke;
  }

  .secondary-background-alt-2 {
    background-color: rgb(234, 234, 234);
  }

  .tertiary-background {
    background-color: map-get($default-background, tertiary-background);
  }

  .quaternary-background {
    background-color: map-get($default-background, quaternary-background);
  }

  .card-background {
    background-color: map-get($default-background, card);
  }

  .overlay-background {
    background-color: white;
  }

  .overlay-background-2 {
    background-color: white;
  }

  .hover-background {
    background-color: map-get($default-background, hover);
  }

  .hovered-background:hover {
    background-color: map-get($default-background, hover);
  }

  .shadow-1 {
    box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
  }

  .default-border {
    border: 1px solid map-get($default-foreground, divider);
  }

  .secondary-border {
    border: 1px solid map-get($default-background, background);
  }

  .secondary-border-alt {
    border: 1px solid map-get($eeule-palette, A85);
  }

  .default-border-color {
    border-color: map-get($default-foreground, divider) !important;
  }

  .secondary-border-color {
    border-color: map-get($default-background, background) !important;
  }

  .secondary-border-color-alt {
    border-color: map-get($eeule-palette, A85) !important;
  }

  .eeule-border-color {
    border-color: mat.get-theme-color($default-theme, primary, lighter) !important;
  }

  .v-divider {
    width: 1px;
    background: map-get($default-foreground, divider);
  }

  .v-divider-2 {
    width: 1px;
    background: map-get($default-background, background);
  }

  a {
    color: mat.get-theme-color($default-theme, primary, lighter)
  }
}

/* default mobile login theme adjustments */
.default-theme:has(.login.handset) {
  background-color: white;
}

/* dark theme */
body:has(.dark-theme) {
  @include mat.core-color($dark-theme);
  @include mat.all-component-colors($dark-theme);
  @include page-header($dark-theme);
  @include logo($dark-theme);
  @include mat.sidenav-color($dark-theme);
  @include sidenav($dark-theme);
  @include card-theme($dark-theme);
  @include highlights($dark-theme);
  @include overlay($dark-theme);
  @include buttons($dark-theme);
  @include form-field($dark-theme);
  @include dialog($dark-theme);
  @include scrollbar($dark-theme);
  @include drag($dark-theme);
  @include context-navigation($dark-theme);
  @include icons($dark-theme);
  @include tabs-theme($dark-theme);
  @include custom-table($dark-theme);
  @include quill($dark-theme);

  .contrast {
    color: white;
  }

  .primary-text {
    color: map-get($dark-foreground, text);
  }

  .secondary-text {
    color: map-get($dark-foreground, secondary-text);
  }

  .disabled-text {
    color: map-get($dark-foreground, disabled-text);
  }

  .primary-background {
    background: map-get($dark-background, background);
  }

  .secondary-background {
    background-color: map-get($dark-background, secondary-background);
  }

  .secondary-background-alt {
    background-color: #3d434e;
    //background-color: #aaaaaa11;
  }

  .secondary-background-alt-2 {
    background-color: #4b5564;
  }

  .tertiary-background {
    background-color: map-get($dark-background, tertiary-background);
  }

  .quaternary-background {
    background-color: map-get($dark-background, quaternary-background);
  }

  .card-background {
    background-color: map-get($dark-background, card);
  }

  .overlay-background {
    background-color: map-get($eeule-palette, 700);
  }

  .overlay-background-2 {
    background-color: map-get($eeule-palette, 800);
  }

  .hover-background {
    background-color: map-get($dark-background, hover);
  }

  .hovered-background:hover {
    background-color: map-get($dark-background, hover);
  }

  .shadow-1 {
    box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  a {
    color: mat.get-theme-color($dark-theme, primary);
  }

  .default-border {
    border: 1px solid map-get($dark-foreground, divider);
  }

  .secondary-border {
    border: 1px solid map-get($dark-background, background);
  }

  .secondary-border-alt {
    border: 1px solid map-get($eeule-palette, A75);
  }

  .default-border-color {
    border-color: map-get($dark-foreground, divider) !important;
  }

  .secondary-border-color {
    border-color: map-get($dark-background, background) !important;
  }

  .secondary-border-color-alt {
    border-color: map-get($eeule-palette, A75) !important;
  }


  .eeule-border-color {
    border-color: mat.get-theme-color($dark-theme, primary, default) !important;
  }

  .v-divider {
    width: 1px;
    height: 80%;
    background: map-get($dark-foreground, divider);
  }

  .v-divider-2 {
    width: 1px;
    background: map-get($dark-background, background);
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font: mat.get-theme-typography($default-theme, body-1);
  letter-spacing: mat.get-theme-typography($default-theme, body-1, letter-spacing);
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.normal {
  font-weight: 400;
}

.strong {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

/* --- layout --- */
.transparent {
  background-color: transparent !important;
}

.hidden {
  display: none !important;
}

.position-relative {
  position: relative;
}

.square {
  border-radius: 0 !important;
}

.small-radius {
  border-radius: 4px;
}

.default-radius {
  border-radius: 5px;
}

.semi-round {
  border-radius: 15px !important;
}

.light-text {
  color: map-get($eeule-palette, 100);
}

.tertiary-text {
  color: map-get($eeule-palette, 300);
}

.tertiary-border-color {
  border-color: map-get($eeule-palette, 200) !important;
}

.dark-background {
  background-color: map-get($eeule-palette, 800);
}

.disabled-link {
  pointer-events: none!important;
}

.no-shadow {
  box-shadow: unset !important;

  &:hover, &:focus, &:active {
    box-shadow: unset !important;
  }
}

.shadow-2 {
  box-shadow: rgba(0, 0, 0, 0.45) 0 25px 20px -20px !important;
}

.no-wrap {
  white-space: nowrap;
}

.h-unset {
  height: unset;
}

.h-auto {
  height: auto;
}

.h-100-percent {
  height: 100%;
}

.maxh-unset {
  max-height: unset !important;
}

.maxh-100-percent {
  max-height: 100%;
}

.minh-unset {
  min-height: unset !important;
}

.w-100-percent {
  width: 100% !important;
}

.default-title {
  font-weight: 500;
}

.default-sub-title {
  font-size: .9em;
  font-weight: 500;
}

.loading-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.covering {
    width: 100%;
    z-index: 2;
  }
}

.scrollable {
  overflow-y: auto;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.smaller-padding {
  padding: 1rem !important;
}

.semi-small-padding {
  padding: .75rem !important;
}

.small-padding {
  padding: .5rem !important;
}

.default-padding {
  padding: 1.5rem;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.center {
  margin: auto;
}

.border-bottom-without-last-child {
  border-bottom: 1px solid;

  &:last-child {
    border-bottom: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

/* font */
.slim {
  font-weight: 400 !important;
}

/* flexbox */
.flexbox {
  display: flex !important;

  &.hidden {
    display: none !important;
  }

  &.direction-column {
    flex-direction: column;
  }

  &.align-center {
    align-items: center;
  }

  &.align-start {
    align-items: start;
  }

  &.align-end {
    align-items: end;
  }

  &.justify-start {
    justify-content: start;
  }

  &.justify-end {
    justify-content: end;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-spaced {
    justify-content: space-between;
  }

  &.centered {
    align-items: center;
    justify-content: center;
  }

  &.xl-gap {
    gap: 4rem;
  }

  &.large-gap {
    gap: 3rem
  }

  &.larger-gap {
    gap: 2rem
  }

  &.default-gap {
    gap: 1.5rem
  }

  &.smaller-gap {
    gap: 1rem;
  }

  &.semi-small-gap {
    gap: .75rem
  }

  &.small-gap {
    gap: .5rem
  }

  &.xs-gap {
    gap: .25rem;
  }
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

/* grid */
.grid {
  display: grid;

  .left {
    grid-area: left;
  }

  .middle {
    grid-area: middle;
  }

  .right {
    grid-area: right;
  }

  .full {
    grid-area: full;
  }
}

/* tooltips */
.custom-tooltip {
  > div {
    max-height: unset;
    padding: .5rem;
    line-height: 18px !important;
  }

  &.w400px {
    > div {
      max-width: 400px;
    }
  }

  &.w25 {
    > div {
      max-width: 25%;
    }
  }

  &.w50 {
    > div {
      max-width: 50%;
    }
  }

  &.w75 {
    > div {
      max-width: 75%;
    }
  }
}

.progress-indicator-tooltip {
  font-size: 13px;

  .progress-indicator-tooltip-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding-bottom: 6px;
    margin-bottom: 6px;
    border-bottom: 1px solid;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .progress-indicator-tooltip-category {
      width: 100%;
      margin-right: .5rem;
      display: flex;
      align-items: center;
      gap: .5rem;
    }

    .progress-indicator-tooltip-values {
      white-space: nowrap;
      text-align: right;
    }
  }
}

/* material */
.filled-text-field-background {
  background-color: var(--mdc-filled-text-field-container-color);
}

/* global adjustments */
.page-outlet:has(.pre-check-subject) + * {
  height: 100%;
}
